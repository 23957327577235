import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/Layout"
import PageTitle from '../components/PageTitle'
import SEO from '../components/seo'

import useStyles from '../styles/generalStyles'

const News = () => {
  const classes = useStyles()
  const query = useStaticQuery(graphql`
{
    allMarkdownRemark(filter: {frontmatter: {id: {eq: "news"}}}) {
      nodes {
        frontmatter {
          title
          seoDescription
          seoKeywords
          seoTitle
        }
        html
      }
    }
  }
`)

  const pageTitle = query.allMarkdownRemark.nodes[0].frontmatter.title
  const seoDescription = query.allMarkdownRemark.nodes[0].frontmatter.seoDescription
  const seoKeywords = query.allMarkdownRemark.nodes[0].frontmatter.seoKeywords
  const seoTitle = query.allMarkdownRemark.nodes[0].frontmatter.seoTitle
  return (
    <Layout>
      <SEO title={seoTitle.length !== 0 ? seoTitle : pageTitle} description={seoDescription.length !== 0 ? seoDescription : null} keywords={seoKeywords.length !== 0 ? seoKeywords : null}/>
      <PageTitle title={pageTitle} />
      <div className={classes.main} dangerouslySetInnerHTML={{ __html: query.allMarkdownRemark.nodes[0].html }} />
    </Layout>
  )
}
export default News
